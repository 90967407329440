var generic = generic || {};

(function ($) {
  'use strict';

  // variables
  var earlyAccessPopupV1 = {
    signed_in: !!parseInt(site.userInfoCookie.getValue('signed_in')),
    loyalty_level: parseInt(site.userInfoCookie.getValue('loyalty_level')),
    is_loyalty_member: parseInt(site.userInfoCookie.getValue('is_loyalty_member')),
    early_access_product_ids: [],
    restricted_products: {},
    revalidate: 0,
    show_overlay: 0,
    page_refresh: 0,
    successful_validation: 0,
    add_to_bag_button: $('.js-add-to-cart, .js-add-to-bag'),
    hiddenContainers: new Array(
      'js-retricted-signin-form-container',
      'js-loyalty-point-restriction',
      'js-loyalty-tier-restriction',
      'js-loyalty-tier-restriction-signin',
      'js-earn-more',
      'js-not-enrolled'
    )
  };

  Drupal.behaviors.earlyAccessPopupV1 = {
    get: function (key) {
      return earlyAccessPopupV1[key];
    },

    set: function (key, val) {
      earlyAccessPopupV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var $template = $('.js-early-access-popup--v1', context);

      // initialize cta buttons
      self._initButtons($template);

      var early_access_product_ids = [];
      var check_access_permissions = false;

      // find the product(s) on the page see if any are restricted
      $('.product-full, .product-brief', context).each(function () {
        var thisProduct = $(this).data('product-id');
        var product = prodcat.data.getProduct(thisProduct);

        // the product is restricted
        if (product && product.RESTRICT_GROUP) {
          early_access_product_ids.push(thisProduct);
          check_access_permissions = true;
        }
      });

      self.set('early_access_product_ids', early_access_product_ids);

      // we have products with restrictions so check to see if the user can access them
      if (check_access_permissions) {
        self.checkEarlyAccessRestriction($template);
      }
    },

    /**
     * Validate the user's loyalty status by checking to see if they can access the product(s)
     */
    earlyAccessValidateLoyalty: function ($template) {
      var self = this;
      var early_access_product_ids = self.get('early_access_product_ids');
      var isProMember = site.userInfoCookie.getValue('is_pro');
      var loyalty = self.get('is_loyalty_member');

      generic.jsonrpc.fetch({
        method: 'user.validateEarlyAccess',
        params: [
          {
            prod_ids: early_access_product_ids
          }
        ],
        onSuccess: function (result) {
          var responseData = result.getValue();

          if (responseData.restricted_products) {
            self.set('restricted_products', responseData.restricted_products);
          }

          // user is still not allowed to buy after checking the permissions so show them the popup and tell them why
          if (responseData.allowed_to_buy !== 1 || (isProMember && !loyalty)) {
            self.setLoyaltyContent($template);
            $.colorbox.resize();
          } else {
            self.successfulValidation();
          }
        }
      });
    },

    successfulValidation: function () {
      var self = this;

      self.set('successful_validation', 1);

      $(document).on('product.updateInvStatusFinished', function () {
        // enable add to bag and close the overlay
        var $addBtn = self.get('add_to_bag_button');

        $addBtn.removeClass('button--disabled').removeAttr('disabled');
      });

      generic.overlay.hide();
      if (self.get('page_refresh')) {
        location.reload();
      }
      // console.log('early access changes button');
    },

    /**
     * Decide which loyalty content to show the user in the overlay
     */
    setLoyaltyContent: function ($template) {
      var self = this;
      var $wrapper = $template.find('.restricted-loyalty-sign-in-component');
      var signedIn = self.get('signed_in');
      var loyalty = self.get('is_loyalty_member');
      var currentLoyaltyLevel = self.get('loyalty_level');
      var firstName = site.userInfoCookie.first_name || '';
      var userDetected = !!firstName;
      var args = {
        signedIn: signedIn,
        isLoyaltyMember: loyalty
      };
      // reset all containers back to their default state
      // this is to handle cases where we are revalidating after user state change
      var $overlaySigninForm = $wrapper;

      $.each(earlyAccessPopupV1.hiddenContainers, function (index, value) {
        $overlaySigninForm.find('.' + value).addClass('hidden');
      });

      // container that show form errors
      var errorContainers = ['form--errors--loyalty-restricted-signin', 'form--errors--loyalty-cta'];

      // reset errors
      // this is to handle cases where we are revalidating after user state change
      $.each(errorContainers, function (index, value) {
        $('#' + value).html();
      });

      // reset the 'show_overlay' param if we are revalidating
      if (self.get('revalidate')) {
        self.set('show_overlay', 0);
      }

      // user is signed in or detected and a loyalty member so check if they have access
      if (!signedIn || ((signedIn || userDetected) && loyalty)) {
        // console.log('user is not signed in or they are a detected loyalty user');
        // Restricted Page by points
        // Restricted Page by tier
        if (!signedIn) {
          self.set('show_overlay', 1);
        } else {
          args = self.getAccessArguments($template, args);
          self.set('show_overlay', 1);
        }
      } else if (signedIn && !loyalty) {
        // show them the popup that allows them to join smashcash
        // Restricted Page - Error Message
        // console.log('show popup to join smashcash');
        self.set('show_overlay', 1);
        args.level_access = 1;
      }

      // if we still want to show the overlay set the necessary information
      if (self.get('show_overlay')) {
        self.setPopupContent($template, args);
      } else if (self.get('revalidate')) {
        // close the overlay if its open and we are revalidating
        // generic.overlay.hide();
      }
    },

    /**
     * Gets/sets additional arguments that will determine what content to show the user based on their tier.
     */
    getAccessArguments: function ($template, args) {
      var self = this;

      args.level_access = 1;

      return args;

      // i am going to leave all this logic here as the scope of the project did a 180 after this was complete
      // and its very possible they will want this logic back once the code is live
      // please refer to initial commit of this file for all of the logic
      var early_access_product_ids = self.get('early_access_product_ids');
      var restricted_products = self.get('restricted_products');
      var current_loyalty_level = parseInt(site.userInfoCookie.getValue('loyalty_level'));

      $.each(early_access_product_ids, function (index, product_id) {
        // skip to the next one if the products we checked isnt restricted
        if (!restricted_products[product_id]) {
          return true;
        }

        // put the tiers in lowest to highest order then take the lowest tier
        // and decide whether to show the user a tier message or a points message
        var tiers = restricted_products[product_id];

        tiers.sort(function (a, b) {
          return a > b ? 1 : -1;
        });
        var tier = tiers[0];

        // show points message if we are only 1 tier away
        if (tier - current_loyalty_level == 1) {
          var points_to_next_level = parseInt(site.userInfoCookie.getValue('points_to_next_level')) || 0;

          // convert points to dollars
          // its a 1:1 spend -> points conversion for users who are tier 2 and under
          args.needs_to_spend = points_to_next_level;
          args.next_tier = current_loyalty_level + 1;
        } else {
          // show tier based message
          // example: user is tier 1 but needs to be tier 3 to see the product
          args.level_access = 1;
        }
      });

      return args;
    },

    /**
     * Sets the content in the overlay and shows it to the user
     */
    setPopupContent: function ($template, args) {
      var self = this;
      var $wrapper = $template.find('.restricted-loyalty-sign-in-component');
      var signedIn = self.get('signed_in');
      var level_access = args.level_access || 0;
      var needs_to_spend = args.needs_to_spend || 0;
      var isLoyaltyMember = args.isLoyaltyMember;
      var next_tier = args.next_tier;
      var currentLoyaltyLevel = self.get('loyalty_level');
      var $signin = $wrapper.find('.js-retricted-signin-form-container');
      var $pointRestrictionMsg = $wrapper.find('.js-loyalty-point-restriction');
      var $tierRestrictionMsg = $wrapper.find('.js-loyalty-tier-restriction');
      var $tierRestrictionSigninMsg = $wrapper.find('.js-loyalty-tier-restriction-signin');
      var $earnMore = $wrapper.find('.js-earn-more');
      var $notEnrolled = $wrapper.find('.js-not-enrolled');
      // var $tier = $wrapper.find('.js-tier');
      // var $next_tier = $wrapper.find('.js-next_tier');

      // find and replace content with loyalty details
      // $wrapper.find('.js-amount').html(needs_to_spend);

      // user is not signed in
      // show signin text in conjunction with other messaging
      if (!signedIn) {
        // console.log('not signed in + tierRestrictionMsg');
        $signin.removeClass('hidden');
        $tierRestrictionMsg.removeClass('hidden');
        $tierRestrictionSigninMsg.removeClass('hidden');
      }

      // Restricted Page - Error Message - Not Member
      if (signedIn && !isLoyaltyMember) {
        // console.log('notEnrolled + tierRestrictionMsg');
        $tierRestrictionMsg.removeClass('hidden');
        $notEnrolled.removeClass('hidden');
      } else if (level_access) {
        // Restricted Page - Restricted By Tier
        // Restricted Page - Error Message - Already Member
        // console.log('notEnrolled + level_access');
        $earnMore.removeClass('hidden');
        $tierRestrictionMsg.removeClass('hidden');
      } else if (needs_to_spend) {
        // Restricted Page - Restricted By Points/Spend
        // console.log('pointsNeeded + pointRestrictionMsg');
        if (signedIn) {
          // $next_tier.html(next_tier);
          $pointRestrictionMsg.removeClass('hidden');
          $earnMore.removeClass('hidden');
        }
      }

      // show popup
      if (!self.get('revalidate')) {
        self.earlyAccessColorbox($template);
      }
    },

    /**
     * Show colorbox with loyalty content
     */
    earlyAccessColorbox: function ($template) {
      var self = this;
      var host_name = 'https://' + window.location.hostname;
      var popup_width = '50%';

      if (site.client.isMobile == 1) {
        popup_width = '25%';
      }
      generic.overlay.launch({
        href: $template,
        inline: true,
        width: popup_width,
        fixed: false,
        escKey: false,
        closeButton: true,
        overlayClose: false,
        scrolling: false,
        cssClass: 'loyalty-early-access-overlay',
        onCleanup: function () {
          // only redirect the user if they havent successfully validated and are closing the popup
          // this is handle calls by generic.overlay.hide()
          var signedIn = self.get('signed_in');
          var loyalty = self.get('is_loyalty_member');
          var currentLoyaltyLevel = self.get('loyalty_level');

          if (!self.get('successful_validation')) {
            if (!signedIn) {
              window.location.href = host_name;
            } else if (!loyalty) {
              window.location.href = host_name + '/smashcash-marketing';
            } else if (currentLoyaltyLevel == 1) {
              window.location.href = host_name + '/top-products';
            }
          }
        },
        onClosed: function () {
          $.each(earlyAccessPopupV1.hiddenContainers, function (index, value) {
            $template.find('.' + value).addClass('hidden');
          });
        },
        onComplete: function () {
          $(this).colorbox.resize();
        }
      });
    },

    /**
     * Check the permission for the user if they are signed in or if they are not
     * show the popup and make them signin so we can validate
     */
    checkEarlyAccessRestriction: function ($template) {
      var self = this;
      var signed_in = self.get('signed_in');

      // check the permissions for the user
      if (signed_in) {
        self.earlyAccessValidateLoyalty($template);
      } else {
        // require them to sign in and then the permissions will be checked
        self.setLoyaltyContent($template);
      }
    },

    // init sign in and join form buttons
    _initButtons: function ($template) {
      var self = this;
      var $wrapper = $template.find('.restricted-loyalty-sign-in-component');

      // init forgot password link
      site.signin.forgotPassword({
        resetPassword: true,
        emailNode: $('input#sign-in-component__EMAIL_ADDRESS', $wrapper),
        errorListNode: $('.signin-block__lost-pass-text', $wrapper),
        forgotPasswordLink: $('#forgot-password', $wrapper),
        forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
      });

      // bind join loyalty form
      $template
        .find('.js-loyalty-cta__button')
        .once()
        .click(function (e) {
          e.preventDefault();

          var params = {
            _SUBMIT: 'loyalty_join',
            LOYALTY_ACTIVE_FLAG: 1,
            ACCEPTED_LOYALTY_TERMS: 1,
            BIRTH_MONTH: $wrapper.find('.month option:selected').val(),
            BIRTH_DAY: $wrapper.find('.day option:selected').val()
          };

          // try and enroll user into loyalty
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              site.userInfoCookie.init();

              // set user as loyalty member
              self.set('is_loyalty_member', parseInt(site.userInfoCookie.getValue('is_loyalty_member')));

              // set user as tier after enrollment
              self.set('loyalty_level', parseInt(site.userInfoCookie.getValue('loyalty_level')));

              // revalidate user info
              self.set('revalidate', 1);
              self.earlyAccessValidateLoyalty($template);
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $template.find('#form--errors--loyalty-cta');

              generic.showErrors(errorObjectsArray, errListNode, $wrapper);
              $.colorbox.resize();
            }
          });
        });

      // bind signin form
      var returnURL = returnURL || window.location.pathname + window.location.search;

      $wrapper.find('input[name=RETURN_URL]').val(returnURL);

      $wrapper
        .find('.js-restricted-loyalty-signin__button')
        .once()
        .click(function (e) {
          e.preventDefault();

          var params = {
            _SUBMIT: 'signin',
            EMAIL_ADDRESS: $wrapper.find('#restricted-loyalty-sign-in-component__EMAIL_ADDRESS').val(),
            PASSWORD: $wrapper.find('#restricted-loyalty-sign-in-component__PASSWORD').val(),
            RETURN_URL: returnURL
          };

          // try and sign the user in
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              //  set the new signed_in values
              site.userInfoCookie.init();
              self.set('signed_in', parseInt(site.userInfoCookie.getValue('signed_in')));
              self.set('is_loyalty_member', parseInt(site.userInfoCookie.getValue('is_loyalty_member')));
              self.set('loyalty_level', parseInt(site.userInfoCookie.getValue('loyalty_level')));

              // revalidate user info
              self.set('revalidate', 1);
              self.set('page_refresh', 1);
              self.earlyAccessValidateLoyalty($template);
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $template.find('#form--errors--loyalty-restricted-signin');

              generic.showErrors(errorObjectsArray, errListNode, $('#loyalty_enrollment'));
              $.colorbox.resize();
            }
          });
        });
    }
  };
})(jQuery);
